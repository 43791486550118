export const ENDPOINT_BASEURL = 'https://clientinfo-dev.synergipartners.com/api/public';

export const environment = {
  production: false,
  auth: {
    domain: 'login-dev.synergipartners.com',
    clientId: 'DO00Fc5VwR6pQmOtPqOioCd2qwC02rBP',
    audience: 'sypa-dev-client-intake-auth',
    redirect: 'https://clientintake-dev.synergipartners.com',
  },
  httpInterceptor: {
    allowedList: [`${ENDPOINT_BASEURL}/*`],
  },
  api: {
    getCIFNotification: `${ENDPOINT_BASEURL}/getCIFNotification`,
    getClient: `${ENDPOINT_BASEURL}/getClient`,
    getClientIntakeFormData: `${ENDPOINT_BASEURL}/getClientIntakeFormData`,
    saveClientIntakeForm: `${ENDPOINT_BASEURL}/saveClientIntakeForm`,
    clientIntakeFormFileUpload: `${ENDPOINT_BASEURL}/clientIntakeFormFileUpload`,
    clientIntakeFormFileDelete: `${ENDPOINT_BASEURL}/clientIntakeFormFileDelete`,
    renameBlobStorageFolder: `${ENDPOINT_BASEURL}/renameBlobStorageFolder`,
    locationListingFileUpload: `${ENDPOINT_BASEURL}/ciflocationlistingfileupload`,
    authTest: `${ENDPOINT_BASEURL}/authTest`,
  },
  // Note: These keys are populated at deployment time (see: inject-api-function-key-secrets.ps1)
  apiKey: 'JYW48bNWKAOiwjzqi5Tl6YwveDROYmr65wwYKCvU+orfg4bjqtejtA==',
  version: '1.0.54738+sha.fa23d91',
};
